import React, {Component} from 'react';
import './Tutorial.css';
import {Container, Grid, Typography} from '@material-ui/core';
import * as config from "../../config/config";

import downloadWalletIcon from "../../assets/images/downloadWallet.svg";
import downloadWalletIconOff from "../../assets/images/downloadWalletoff.svg";
import verifyIcon from "../../assets/images/verifyIDIcon.svg";
import verifyIDOff from "../../assets/images/verifyIDoff.svg";
import governmentIcon from "../../assets/images/governmentIcon.svg";
import governmentIconOff from "../../assets/images/governmentIconoff.svg";
import universityIcon from "../../assets/images/universityIcon.svg";
import universityIconOff from "../../assets/images/universityIconoff.svg";
import vididentityWhite from "../../assets/images/vididentityWhite.svg";
import vididentityWhiteOff from "../../assets/images/vididentityWhiteOff.svg"
import HeaderTutorial from '../../components/HeaderTutorial/HeaderTutorial';
import Panel from '../../components/Panel/Panel';
import {RestartButton} from '../../components/RestartButton/RestartButton';
import PanelDoubleOption from "../../components/PanelDoubleOption/PanelDoubleOption";


interface Props {
  location: any;
}

interface State {
  step: number;
  isProcessEnded: boolean;
}



class Tutorial extends Component<Props, State> {
  step0Ref;
  step1Ref;
  step2Ref;
  step3Ref;
  step4Ref;
  step5Ref;

  constructor(props: any) {
    super(props);
    this.state = {
      step: +(sessionStorage.getItem("step") || 0),
      isProcessEnded: false
    };
    this.step0Ref = React.createRef();
    this.step1Ref = React.createRef();
    this.step2Ref = React.createRef();
    this.step3Ref = React.createRef();
    this.step4Ref = React.createRef();
    this.step5Ref = React.createRef();

    this.continue = this.continue.bind(this);
  }

  componentDidMount() {
    const code = new URLSearchParams(this.props.location.search).get("step");
    this.setState({
      step: 0,
    });
    if(code){
      const intCode: number = parseInt(code);
      this.setState({
        step: intCode,
      });
      this.changeFocus(intCode);
    }
    const codeFromStorage = sessionStorage.getItem("step");
    if(codeFromStorage){
      const intCode: number = parseInt(codeFromStorage);
      this.setState({
        step: intCode,
      });
      this.changeFocus(intCode);
    }
    sessionStorage.setItem("tutorial", "true");
  }

  continue(){
    const {step} = this.state;
    sessionStorage.setItem("step", String(step+1))
    this.redirectTo(step);
  }

  skipIssuance() {
    sessionStorage.setItem("step", String(2))
    window.location.reload();
  }

  changeFocus(code:number){
    if(code === 1 ) this.step1Ref.current?.scrollIntoView();
    if(code === 2 ) this.step2Ref.current?.scrollIntoView();
    if(code === 3 ) this.step3Ref.current?.scrollIntoView();
    if(code === 4) this.step4Ref.current?.scrollIntoView();
    if(code === 5){
      this.setState({
        isProcessEnded: true,
      });
      this.step4Ref.current?.scrollIntoView();
    }
  }

  redirectTo(step: number){
    if(step === 0){
      window.location.replace("/demo?step=1");
      this.setState({
        step: 1,
      });
    }
    if(step === 1){
      const currentUrl = window.location.href
      const nextStepUrl = currentUrl.replace("step=1", "step=2")
      window.location.replace(`${config.VIDISSUER_URL}?redirect_url=${nextStepUrl}`);
    }
    if(step === 2){
      window.location.replace(config.GOVERNMENT_URL);
    }
    if(step === 3){
      window.location.replace(config.UNIVERSITY_URL);
    }
    if(step === 4){
      window.location.replace(config.VIDPARTNERS_URL);
    }
    if(step === 5){
      this.setState({
        isProcessEnded: true,
      });
    }
  }

  restart(){
    this.setState({
      step: 0,
    });
    sessionStorage.setItem("step", String(0));
    window.location.replace("/demo");
  }
render() {
  const {step,isProcessEnded} = this.state;
  return (
    <Container>
      <HeaderTutorial />
      <Grid container
        direction="column"
        justify="center"
        alignItems="center"
        className="content">
          <Grid item className="titleHome">
            <Typography variant="h3"><b>{"Siga los siguientes pasos para probar VIDidentity"}</b></Typography>
          </Grid>
          <Grid container
            direction="column"
            justify="space-between"
            alignItems="center"
            className="panels">
            <Panel
                title="Descargue VIDwallet"
                panelText="La aplicación está disponible para Android en la Play Store y para iOS en la App Store."
                stepPanel={0}
                stepSelected={step}
                iconOn={downloadWalletIcon}
                iconOff={downloadWalletIconOff}
                textButton="Ya la tengo instalada"
                refPanel={this.step0Ref}
                functionClickButton={this.continue}/>
            <PanelDoubleOption
                title="Obtenga su primera credencial verificable"
                panelText="Una vez que haya instalado VIDwallet, está listo para emitir su primera credencial. Navegue a la página de VIDissuer para emitir una EmailCredential. Es importante que seleccione este tipo, ya que se necesitará en los siguientes pasos."
                stepPanel={1}
                stepSelected={step}
                iconOn={verifyIcon}
                iconOff={verifyIDOff}
                textButton1="Conseguir credencial de email"
                textButton2="Ya dispongo de una credencial de email"
                refPanel={this.step1Ref}
                functionClickButton1={this.continue}
                functionClickButton2={this.skipIssuance}/>
            <Panel
                title="Gobierno de Freedonia"
                panelText="Vaya al Gobierno de Freedonia e inicie sesión utilizando el eID verificable que tiene en su billetera. Una vez que haya accedido al portal, puede solicitar la credencial de Familia Numerosa que puede utilizar en otras entidades para solicitar descuentos."
                stepPanel={2}
                stepSelected={step}
                iconOn={governmentIcon}
                iconOff={governmentIconOff}
                textButton="Ir a Freedonia"
                refPanel={this.step2Ref}
                functionClickButton={this.continue}/>

            <Panel
                title="ACME University"
                panelText="Inicie sesión con VIDidentity en ACME university. Aquí puede solicitar una nueva credencial de estudiante. Para completar este tutorial, solicite un descuento en su matrícula presentando la credencial de Familia Numerosa que ya tiene en su billetera."
                stepPanel={3}
                stepSelected={step}
                iconOn={universityIcon}
                iconOff={universityIconOff}
                textButton="Ir a Acme University"
                refPanel={this.step3Ref}
                functionClickButton={this.continue}/>
            <Panel
                title="Evento VIDpartners 2024"
                panelText="Después de explorar cómo obtener y presentar credenciales en las páginas de demostración anteriores, ¡está listo para recibir su credencial de VIDpartnersevent2024! Esta credencial le brindará la oportunidad de participar en un sorteo el día del evento."
                stepPanel={4}
                stepSelected={step}
                iconOn={vididentityWhite}
                iconOff={vididentityWhiteOff}
                textButton="Ir a VIDpartnersevent2024"
                refPanel={this.step4Ref}
                functionClickButton={this.continue}/>
          </Grid>

          <Grid item className="restartProcess" ref={this.step5Ref}>
              {isProcessEnded &&
                <>
                  <Typography variant="subtitle1" className="bodyText">
                  {'¡Ha concluido con éxito la demostración de VIDidentity!'}
                  </Typography>
                  <Typography variant="subtitle1" className="bodyText">
                  {'Puede reiniciar el proceso si lo desea haciendo clic en el botón a continuación:'}
                  </Typography>
                </>
              }
              <RestartButton variant="contained" onClick={()=> this.restart()} className="restartButton">
                  {"Reiniciar proceso"}
              </RestartButton>
          </Grid>
      </Grid>


    </Container>
  );
  }
}


export default Tutorial
