import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import "./Panel.css";
import {NextButton} from '../NextButton/NextButton';

import logoGooglePlay from "../../assets/images/playStore.png";
import logoIOS from "../../assets/images/appStore.png";

type Props = {
  title: string;
  panelText: string;
  iconOn: any;
  iconOff: any;
  stepPanel: number;
  stepSelected: number;
  textButton: string;
  functionClickButton: any;
  refPanel: any;
};

const Panel = (props: Props) => {
  const {title, panelText, iconOn, iconOff, stepPanel, stepSelected, textButton, functionClickButton, refPanel} = props;
  return (
    <div className={stepSelected === stepPanel ? (stepPanel === 0 ? "containerProfileOn0" : "containerProfileOn") :  "containerProfile"} ref={refPanel}>
        <Grid container
            direction="row">
              <Grid item lg={2} sm={12} xs={8}>
                <img
                    src={stepSelected === stepPanel ? iconOn : iconOff}
                    alt=""
                    role="presentation"
                    className={stepSelected === stepPanel ? "panelImageOn" : "panelImageOff"}
                />
                </Grid>
          <Grid item lg={9} sm={12} xs={10} className={stepSelected === stepPanel ? "panelTitleOn" : "panelTitle"}>
            <Typography variant="h5">{`Paso ${stepPanel}`}</Typography>
            <Typography variant="h5">{title}</Typography>
            <div className={stepSelected === stepPanel ? "bodyTextOn" : "bodyTextOff"}>
              <Typography variant="subtitle1" className="bodyTextPanel">{panelText}</Typography>

              {/* Wrapper div for buttons, styled as a flex container */}
              <div className="downloadAppButtons">
                {stepPanel === 0 &&
                    <NextButton className="buttonPanel" variant="contained" style={{ textTransform: 'none' }}>
                      {'Descarga en PlayStore'}
                      <a href="https://play.google.com/store/apps/details?id=com.validatedid.wallet"><img className="logoStoreGooglePanel" src={logoGooglePlay}></img></a>
                    </NextButton>
                }

                {stepPanel === 0 &&
                    <NextButton className="buttonPanel" variant="contained" style={{ textTransform: 'none' }}>
                      {'Descarga en AppStore'}
                      <a href="https://apps.apple.com/es/app/vidwallet/id1554340592"><img className="logoStoreiOSPanel" src={logoIOS}></img></a>
                    </NextButton>
                }

                <NextButton className="buttonPanel" variant="contained" onClick={functionClickButton} style={{ textTransform: 'none' }}>
                  {textButton}
                </NextButton>
              </div>
            </div>
          </Grid>


            </Grid>


    </div>
  );
};

export default Panel;
